import * as React from "react";
import { GetServerSideProps } from "next";
import { dehydrate } from "@tanstack/react-query";
import { getSession } from "@tbml/hooks/useSession";
import { Customer } from "../components/Customer";
import {
  getQueryClient,
  prefetchCustomerWithActiveIssueWithStories,
  prefetchCustomers,
  prefetchIsInternalUser,
} from "../lib/prefetch";
import {
  debugPanelCustomerFragment,
  debugPanelCustomerFragmentName,
} from "../components/DebugPanel";

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context);
  if (!session) {
    return { props: {} };
  }

  const queryClient = await getQueryClient(session);

  const {
    query: { customerId },
  } = context;

  // prefetch is internal user
  const isInternalUser = await prefetchIsInternalUser(queryClient);

  // prefetch customers list
  if (isInternalUser) {
    prefetchCustomers(
      queryClient,
      debugPanelCustomerFragmentName,
      debugPanelCustomerFragment
    );
  }

  // prefetch customer
  if ((isInternalUser && typeof customerId === "string") || !isInternalUser) {
    prefetchCustomerWithActiveIssueWithStories(
      queryClient,
      customerId?.toString()
    );
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

function Home(): JSX.Element {
  return <Customer />;
}

export default Home;
